<template>
  <div>
    <svg width="150px" height="150px" viewBox="0 0 150 150" version="1.1" id="SVGRoot" xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
      <defs id="defs26" />
      <g id="layer1">
        <path
          d="m 91.813244,63.678377 c 0,8.667661 -7.028375,15.694725 -15.695698,15.694725 -8.671405,0 -15.699545,-7.027064 -15.699545,-15.694725 0,-8.669983 7.02814,-15.694886 15.699545,-15.694886 8.667323,0 15.695698,7.024903 15.695698,15.694886 z"
          style="fill:#a90051;fill-rule:evenodd;stroke-width:0.892754" id="path89" />
        <path
          d="m 109.95254,56.295577 c 0.51652,2.379345 0.79641,4.846759 0.79641,7.3828 0,19.098434 -15.540022,34.634534 -34.631404,34.634534 -19.099416,0 -34.6455,-15.5361 -34.6455,-34.634534 0,-19.100863 15.546084,-34.638892 34.6455,-34.638892 2.500513,0 4.934724,0.274621 7.293352,0.784098 0.730378,-1.919682 1.735077,-3.696988 2.975127,-5.292074 -3.280694,-0.86516 -6.725306,-1.325116 -10.268479,-1.325116 -22.323423,0 -40.478406,18.15248 -40.478406,40.471984 0,22.317183 18.154983,40.471993 40.478406,40.471993 22.31263,0 40.466904,-18.15481 40.466904,-40.471993 0,-3.586091 -0.47458,-7.060801 -1.35646,-10.374092 -1.58358,1.244233 -3.36613,2.254234 -5.27545,2.991292 z"
          style="fill:#a90051;fill-rule:evenodd;stroke-width:0.892754" id="path91" />
        <path
          d="m 148.37181,124.5678 c 0,3.33076 -2.70213,6.03008 -6.02487,6.03008 H 9.3025051 c -3.3281023,0 -6.0295794,-2.69932 -6.0295794,-6.03008 v -18.94126 c 0,-3.33488 2.7014771,-6.031739 6.0295794,-6.031739 H 142.34694 c 3.32274,0 6.02487,2.696859 6.02487,6.031739 z"
          style="fill:#a90051;fill-rule:evenodd;stroke-width:0.892754" id="path93" />
        <path
          d="m 85.451576,37.114268 c 0,9.459009 7.694918,17.152336 17.153394,17.152336 9.46123,0 17.15472,-7.693327 17.15472,-17.152336 0,-9.461588 -7.69349,-17.157138 -17.15472,-17.157138 -9.458476,0 -17.153394,7.69555 -17.153394,17.157138 z m 2.919756,0 c 0,-7.852219 6.38721,-14.237204 14.233638,-14.237204 7.85063,0 14.23908,6.384985 14.23908,14.237204 0,7.847425 -6.38845,14.234981 -14.23908,14.234981 -7.846428,0 -14.233638,-6.387556 -14.233638,-14.234981 z"
          style="fill:#9b9d9f;fill-rule:evenodd;stroke-width:0.892754" id="path95" />
        <path
          d="m 108.82452,37.114268 c 0,3.42943 -2.78314,6.212059 -6.21955,6.212059 -3.426737,0 -6.20991,-2.782629 -6.20991,-6.212059 0,-3.43209 2.783173,-6.214459 6.20991,-6.214459 3.43641,0 6.21955,2.782369 6.21955,6.214459 z"
          style="fill:#9b9d9f;fill-rule:evenodd;stroke-width:0.892754" id="path97" />
        <path
          d="m 15.538311,116.24595 1.574105,-5.32297 h 0.09731 l 1.574194,5.32297 z m 5.687206,-9.3476 h -7.903739 l -5.3262668,17.00736 h 5.8367438 l 1.021669,-3.67835 h 4.596707 l 1.069699,3.67835 h 6.153404 z"
          style="fill:#ffffff;fill-rule:evenodd;stroke-width:0.892754" id="path111" />
        <path
          d="m 48.773979,123.90571 h -5.253865 v -13.37214 h -0.145967 l -3.015816,13.37214 h -4.863819 l -2.942789,-13.37214 h -0.146068 v 13.37214 h -5.010414 v -17.00736 h 8.707045 l 1.994148,9.31521 h 0.09741 l 1.897372,-9.31521 h 8.682757 z"
          style="fill:#ffffff;fill-rule:evenodd;stroke-width:0.892754" id="path113" />
        <path
          d="m 71.013033,123.28435 c -0.84321,0.27601 -1.795846,0.49897 -2.857536,0.6693 -1.062263,0.16972 -2.225988,0.2551 -3.490298,0.2551 -3.421668,0 -5.926196,-1.03791 -7.515408,-3.11374 -1.167605,-1.54036 -1.751138,-3.4789 -1.751138,-5.81462 0,-0.98917 0.14509,-1.95407 0.437464,-2.89466 0.502519,-1.60528 1.370023,-2.88656 2.60265,-3.84422 1.653826,-1.29681 3.891523,-1.94583 6.713159,-1.94583 0.82634,0 1.653219,0.0446 2.480065,0.13362 0.82715,0.0894 1.751409,0.25576 2.773182,0.49915 v 4.60341 c -0.42201,-0.22641 -0.908131,-0.42863 -1.459305,-0.60709 -0.860081,-0.25813 -1.727553,-0.38824 -2.602548,-0.38824 -1.475366,0 -2.651306,0.43753 -3.527112,1.31189 -0.875535,0.87428 -1.313067,2.11236 -1.313067,3.71497 0,1.39296 0.356484,2.45317 1.070497,3.18139 0.615284,0.61532 1.393608,0.92304 2.334131,0.92304 0.227421,0 0.41317,-0.0135 0.559138,-0.0364 0.146102,-0.0237 0.308401,-0.0604 0.486997,-0.10899 v -4.05231 h 5.059129 z"
          style="fill:#ffffff;fill-rule:evenodd;stroke-width:0.892754" id="path115" />
        <path
          d="m 81.250537,114.26868 c -0.21055,0.0486 -0.541726,0.0732 -0.994712,0.0732 h -1.430827 v -3.63522 h 1.528139 c 0.776267,0 1.350385,0.14947 1.722289,0.44836 0.371937,0.29895 0.557821,0.73891 0.557821,1.32045 0,0.96964 -0.461218,1.56753 -1.38271,1.7932 z m 6.723956,4.70767 c -0.211224,-1.00335 -0.648485,-1.78846 -1.313235,-2.35515 -0.470666,-0.38817 -1.037902,-0.6551 -1.702483,-0.80117 1.053356,-0.19402 1.896566,-0.65591 2.529531,-1.38548 0.696434,-0.79393 1.045325,-1.7906 1.045325,-2.99029 0,-1.8797 -0.753829,-3.17589 -2.261789,-3.88936 -0.940624,-0.43747 -2.237528,-0.65655 -3.891524,-0.65655 h -9.193326 v 17.00736 h 5.88628 v -5.92879 h 1.313067 c 0.437464,0 0.810616,0.0972 1.118983,0.29152 0.437633,0.27602 0.697142,0.73706 0.778393,1.38494 l 0.559239,4.25247 h 6.128849 z"
          style="fill:#ffffff;fill-rule:evenodd;stroke-width:0.892754" id="path117" />
        <path
          d="m 100.34176,118.455 c -0.35703,1.12388 -1.029908,1.68578 -2.01834,1.68578 -0.956792,0 -1.62144,-0.5701 -1.994156,-1.71007 -0.243276,-0.71607 -0.365057,-1.72569 -0.365057,-3.02874 0,-1.31854 0.121817,-2.33611 0.365057,-3.05289 0.356486,-1.1238 1.021035,-1.68581 1.994156,-1.68581 0.972371,0 1.645151,0.57004 2.01834,1.71018 0.24362,0.71677 0.36522,1.72625 0.36522,3.02852 0,1.31937 -0.12181,2.33703 -0.36522,3.05303 z m 4.28111,-9.51189 c -1.4917,-1.56462 -3.592,-2.34756 -6.29945,-2.34756 -2.691835,0 -4.787516,0.78294 -6.287237,2.34756 -1.499731,1.56535 -2.249475,3.71825 -2.249475,6.45886 0,2.74136 0.749744,4.89433 2.249475,6.45897 1.499721,1.56526 3.595402,2.34767 6.287237,2.34767 2.70745,0 4.80775,-0.78241 6.29945,-2.34767 1.49153,-1.56464 2.23732,-3.71761 2.23732,-6.45897 0,-2.74061 -0.74579,-4.89351 -2.23732,-6.45886 z"
          style="fill:#ffffff;fill-rule:evenodd;stroke-width:0.892754" id="path119" />
        <path
          d="m 124.10235,117.23102 c 0,2.02593 -0.52746,3.62246 -1.58098,4.78956 -0.81065,0.89187 -1.90551,1.51599 -3.28356,1.87217 -0.82688,0.21055 -1.76757,0.31583 -2.82103,0.31583 -1.02174,0 -1.94603,-0.10528 -2.77237,-0.31583 -1.41115,-0.35618 -2.51408,-0.97227 -3.30799,-1.84791 -1.07033,-1.167 -1.60517,-2.77156 -1.60517,-4.81382 v -10.33267 h 5.85976 v 10.09404 c 0,0.7458 0.0968,1.34566 0.29085,1.79942 0.29086,0.69779 0.79945,1.04597 1.52706,1.04597 0.72681,0 1.23594,-0.34015 1.52662,-1.02157 0.19368,-0.45379 0.29086,-1.06165 0.29086,-1.82382 v -10.09404 h 5.87612 z"
          style="fill:#ffffff;fill-rule:evenodd;stroke-width:0.892754" id="path121" />
        <path
          d="m 135.20982,114.76917 c -0.388,0.17714 -0.92223,0.26488 -1.6018,0.26488 h -1.28671 v -4.19775 h 1.28671 c 1.01972,0 1.74784,0.22506 2.18476,0.67561 0.33935,0.3382 0.50994,0.81197 0.50994,1.42334 0,0.88444 -0.36451,1.49562 -1.0929,1.83382 z m 5.39598,-6.00378 c -1.16781,-1.24424 -2.84593,-1.86704 -5.03423,-1.86704 h -8.97478 v 17.00736 h 5.88601 v -4.93327 h 3.21071 c 2.18807,0 3.87439,-0.6627 5.05836,-1.98808 0.97231,-1.0988 1.4593,-2.43221 1.4593,-4.00029 0,-1.69719 -0.53565,-3.1031 -1.60537,-4.21868 z"
          style="fill:#ffffff;fill-rule:evenodd;stroke-width:0.892754" id="path123" />
      </g>
    </svg>
  </div>
  <div class="keyboard">

    <span class="key">U</span>
    <span class="key">N</span>
    <span class="key">D</span>
    <span class="key">E</span>
    <span class="key">R</span>

  </div>
  <div class="keyboard">
    <span class="key"></span>
    <span class="key">C</span>
    <span class="key">O</span>
    <span class="key">N</span>
    <span class="key">S</span>
    <span class="key">T</span>
    <span class="key">R</span>
    <span class="key">U</span>
    <span class="key">C</span>
    <span class="key">T</span>
    <span class="key">I</span>
    <span class="key">O</span>
    <span class="key">N</span>
  </div>
  <div class="text"><a href="tel:0954162302" style="">0954162302</a></div>
  <div class="text"><a href="mailto:kovalev.ruslan@amgroup.in.ua">kovalev.ruslan@amgroup.in.ua</a></div>
</template>







<script>


export default {
  name: 'App'

}
</script>

<style>
body {
  background-color: #101013;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  font-family: "Poppins", sans-serif;
  font-weight: 900;
}

a {
  text-decoration: none;
  color: #fff;
  font-size: 2rem;
}

.key {
  /* margin-left: 5px; */
  font-size: 10vw;
  display: inline-block;
  letter-spacing: -.7vw;
  transition: transform 0.2s;
}



@keyframes pressDown1 {

  30%,
  40%,
  100% {
    transform: translateY(0);
  }

  35% {
    transform: translateY(10px);
  }
}

@keyframes pressDown2 {

  70%,
  80%,
  100% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(10px);
  }
}

@keyframes pressDown3 {

  30%,
  40%,
  100% {
    transform: translateY(0);
  }

  35% {
    transform: translateY(10px);
  }
}

@keyframes pressDown4 {

  40%,
  50%,
  100% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(10px);
  }
}

@keyframes pressDown5 {

  20%,
  30%,
  100% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(10px);
  }
}

@keyframes pressDown6 {

  60%,
  70%,
  100% {
    transform: translateY(0);
  }

  65% {
    transform: translateY(10px);
  }
}

@keyframes pressDown7 {

  10%,
  20%,
  100% {
    transform: translateY(0);
  }

  15% {
    transform: translateY(10px);
  }
}

@keyframes pressDown8 {

  35%,
  45%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(10px);
  }
}

@keyframes pressDown9 {

  50%,
  60%,
  100% {
    transform: translateY(0);
  }

  55% {
    transform: translateY(10px);
  }
}

@keyframes pressDown10 {

  80%,
  90%,
  100% {
    transform: translateY(0);
  }

  85% {
    transform: translateY(10px);
  }
}

@keyframes pressDown11 {

  15%,
  25%,
  100% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(10px);
  }
}

@keyframes pressDown12 {

  25%,
  35%,
  100% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(10px);
  }
}

@keyframes pressDown13 {

  45%,
  55%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
}

.key:nth-child(9) {
  animation: pressDown9 3s infinite;
}

.key:nth-child(10) {
  animation: pressDown10 4.5s infinite;
}

.key:nth-child(11) {
  animation: pressDown11 2.8s infinite;
}

.key:nth-child(12) {
  animation: pressDown12 3.8s infinite;
}

.key:nth-child(13) {
  animation: pressDown13 2.3s infinite;
}


.key:nth-child(1) {
  animation: pressDown1 2s infinite;
}

.key:nth-child(2) {
  animation: pressDown2 3s infinite;
}

.key:nth-child(3) {
  animation: pressDown3 4s infinite;
}

.key:nth-child(4) {
  animation: pressDown4 2.5s infinite;
}

.key:nth-child(5) {
  animation: pressDown5 2.5s infinite;
}

.key:nth-child(6) {
  animation: pressDown6 3.5s infinite;
}

.key:nth-child(7) {
  animation: pressDown7 2.2s infinite;
}

.key:nth-child(8) {
  animation: pressDown8 3.2s infinite;
}




/* extra stuff */
.jux-linx {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media only screen and (max-width: 480px) {

  /* Пример: изменение размера текста для мобильных устройств */
  body {
    width: 80%;
    margin: auto;
    justify-content: unset;
    align-items: center;

  }

  a {
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }


}

/* a {
  text-decoration: none;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: white;
  background-color: black;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  padding: 5px 10px;
  transition: 0.1s all ease-in;
}

a:nth-child(1):hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0px 2px 0 #349eff;
}

a:nth-child(2):hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0px 2px 0 #ff5757;
} */
</style>
